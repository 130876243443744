import { useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Grid,
  Space,
  Row,
  Col,
  Alert,
  InputNumber,
  DatePicker,
} from "antd";
import { createOrganizationPlayer } from "../../apiBridge";
import { useParams } from "react-router-dom";
import { regionList } from "../../regionData";
import { MessageInstance } from "antd/es/message/interface";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;


interface Props {
  isOpen: boolean;
  organizationId: string;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  messageController: MessageInstance;
}

const CreatePlayerUserModal: React.FC<Props> = ({
    isOpen,
    organizationId,
    controller,
    reloadController,
    messageController,
  }) => {

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const [OTP, setOTP] = useState("");
    const [showPasswordPage, setShowPasswordPage] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const onFinishCreateUser = async (values: any) => {
        setLoadingState(true);
        try {
            const res = await createOrganizationPlayer(organizationId, values);
            setLoadingState(false);
            if (res.status === 201) {
              const data = await res.json();
              setOTP(data.user.password);
              setShowPasswordPage(true);
              form.resetFields();
              reloadController(true);
            }
        } catch (e: any) {
            messageController.info( e.message )
        }
    };

    return (
        <Modal 
            footer={null} 
            maskClosable={false} centered 
            closeIcon={false}
            open={isOpen} 

            afterOpenChange={(open) => {
                form.resetFields()
                setShowPasswordPage(false);
                setOTP("");
            }}
        >
            <div style={{ marginBottom: 20 }}>
                <Title level={4}>
                    {showPasswordPage ? "User Created" : "Create a User"}
                </Title>
                <Text type="secondary">
                {showPasswordPage
                    ? "The account password will not be shown once this is closed. Please note it down."
                    : "Create a user in your organisation. A password will be automatically created after confirmation."}
                </Text>
            </div>
            {
                !showPasswordPage ? (
                    <Form 
                        form={form} 
                        name="createuserform" 
                        layout="vertical"
                        onFinish={onFinishCreateUser}
                        initialValues={{ location: "" }}
                    >
                        <Row gutter={24}>
                            <Col sm={12} xs={24}>
                                <Form.Item 
                                    label={screens.md ? "Username" : ""}
                                    extra={
                                    <Text type="secondary" style={{ fontSize: 12 }}>
                                        Username is used by users to login to the portal
                                    </Text>
                                    }
                                    name="username"
                                    rules={[
                                    {},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        value = value ? value.trim() : "";
                                        if (value === "") {
                                            return Promise.reject(new Error("Please enter a Username."))
                                        }

                                        if (value.length < 5) {
                                            return Promise.reject(new Error("The username must be at least 5 characters long."))
                                        }

                                        if (value.length > 30) {
                                            return Promise.reject(new Error("The username must be shorter than 30 characters."))
                                        }

                                        if (value.indexOf(" ") > -1) {
                                            return Promise.reject(new Error("The username must not include any spacing."));
                                        }

                                        if (!/[a-zA-Z]/.test(value)) {
                                            return Promise.reject(new Error("The username must include at least 1 letter"));
                                        }
                                        
                                        return Promise.resolve();
                                        },
                                    }),
                                    ]}
                                >
                                    <Input placeholder="Username" />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item
                                    label={screens.md ? "Display Name" : ""}
                                    extra={
                                    <Text type="secondary" style={{ fontSize: 12 }}>
                                        Display name is shown in-game
                                    </Text>
                                    }
                                    name="alias"
                                    rules={[
                                    {},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || value === "") {
                                            return Promise.reject(new Error("Please enter a display name."));
                                        }

                                        if (value.trim() !== value) {
                                            return Promise.reject(new Error("The display name cannot have spaces before or after it."))
                                        }

                                        if (value.length > 25) {
                                            return Promise.reject(new Error("The display name must be shorter than 25 characters."))
                                        }

                                        return Promise.resolve();
                                        },
                                    }),
                                    ]}
                                >
                                    <Input placeholder="Display Name" />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item name="full_name" label="Full name">
                                    <Input placeholder="Full name" />
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={24}>
                                <Form.Item name="birth_year" label="Year of birth">
                                    <DatePicker picker="year" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="phone" label="Contact number">
                            <Input placeholder="contact number" />
                        </Form.Item>
                        
                        <Form.Item
                            label={screens.md ? "Role" : ""}
                            extra={
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                Role of user in the organisation
                            </Text>
                            }
                            name="role"
                            rules={[{ required: true, message: "Please select a role!" }]}
                        >
                            <Select>
                                <Select.Option key="user" value="User">
                                    Player <span className="text-muted">(Able to view personal dashboard)</span>
                                </Select.Option>
                                <Select.Option key="gamemaster" value="GameMaster">
                                    GM <span className="text-muted">(Able to manage players and host matches)</span>
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item 
                            label={screens.md ? "Country / Region" : ""}
                            name="location">
                                <Select>
                                    <Select.Option key="" value="">unknown country / region</Select.Option>
                                    {regionList.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                        {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        <div style={{ textAlign: "right" }}>
                            <Space>
                            <Button onClick={() => controller(false)}>Cancel</Button>

                            <Form.Item style={{ margin: 0 }}>
                                <Button type="primary" htmlType="submit" loading={loadingState}>
                                Create User
                                </Button>
                            </Form.Item>
                            </Space>
                        </div>
                    </Form>
                ) : (
                    <div>
                        <div
                            style={{
                            border: "1px solid #d9d9d9",
                            borderRadius: 4,
                            padding: "4px 8px",
                            height: 32,
                            backgroundColor: "#f5f5f5",
                            }}
                        >
                            <Text className="customCopyInput" type="secondary" copyable>
                            {OTP}
                            </Text>
                        </div>
                        <div style={{ textAlign: "right", marginTop: 20 }}>
                            <Space>
                            <Button onClick={() => controller(false)}>
                                Back to Dashboard
                            </Button>
                            <Button type="primary" onClick={() => setShowPasswordPage(false)}>
                                Add another user
                            </Button>
                            </Space>
                        </div>
                     </div>
                )
            }
        </Modal>
    )
}

export default CreatePlayerUserModal