import { useEffect, useState } from "react";
import { getUserRequestById, updateRequestStatus } from "../../apiBridge";
import { IRequestListItem } from "../../interface";
import { Avatar, Button, Descriptions, List, Modal, Space, Tag } from "antd";
import Title from "antd/es/typography/Title";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
    id: string
    isOpen: boolean;
    isReviewer: boolean;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
    reloadController: React.Dispatch<React.SetStateAction<boolean>>;
    notifController: MessageInstance;
}

const ViewRequestModel: React.FC<Props> = ({
    id,
    isOpen,
    isReviewer,
    controller,
    reloadController,
    notifController
}) => {

    const [requestData, setRequestData] = useState<IRequestListItem | null>(null)
    const [loadingState, setLoadingState] = useState<boolean>(false);

    async function reloadRequest() {
        let res = await getUserRequestById(String(id))
        if (res.status == 200) {
            const data = await res.json() as {
                data: IRequestListItem
            }
    
            setRequestData(data.data);
        }
    }
    
    useEffect(() => {
         if (isOpen) {
            setRequestData(null);
            reloadRequest();
         }
    }, [isOpen])
    
    
    async function rejectRequest(row: IRequestListItem) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('reject?')) {
             return false;
        }
    
        setLoadingState(true);
        const res = await updateRequestStatus(row.id, "Rejected")
        if (res.status === 200) {
            const data = await res.json();
            notifController.success("rejected")
            
            controller(false);
            reloadController(true)
        } else {
            const data = await res.json();
            notifController.info(data.error || "Unknown Error. Please try again next time.");
        }
        setLoadingState(false);
    }
    
    async function acceptRequest(row: IRequestListItem) {
         // eslint-disable-next-line no-restricted-globals
         if (!confirm("approve?")) {
             return false;
         }
    
        setLoadingState(true);
        const res = await updateRequestStatus(row.id, "Accepted")
        if (res.status === 200) {
            const data = await res.json();
            notifController.success("approved")
            
            controller(false);
            reloadController(true)
        } else {
            const data = await res.json();
            notifController.info(data.error || "Unknown Error. Please try again next time.");
        }
        setLoadingState(false);
    
    }

    return <>
        <Modal
            footer={null}
            centered
            open={isOpen}
            onCancel={() => controller(false)}
        >
            { requestData ? <>
                <Title level={4}>{requestData.type}</Title>
                <Descriptions layout="vertical" column={1}>

                    <Descriptions.Item label="Request From">
                        { requestData.from_id }
                    </Descriptions.Item>

                    <Descriptions.Item label="Request User">
                        <Space>
                            <Avatar src={requestData.requester_user?.avatar} /> { requestData.requester_user?.name } 
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Review User">
                        <Space>
                            <Avatar src={requestData.review_user?.avatar} /> { requestData.review_user?.name } 
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Affected Account(s)">
                        <List>
                        {
                            requestData.requested_users.map((user, index) => (
                                <>
                                    <List.Item>
                                        <Space>
                                            <Avatar src={user.avatar} />
                                            {user.name}
                                        </Space>
                                    </List.Item>
                                </>
                            ))
                        }
                        </List>
                    </Descriptions.Item>

                    <Descriptions.Item label="Reason">
                        { requestData.reason }
                    </Descriptions.Item>

                </Descriptions>

                {
                    requestData.status === "Wait" && isReviewer ? <>
                        <Space>
                            <Button type="primary" onClick={() => acceptRequest(requestData)} loading={loadingState}>Approve</Button>
                            <Button danger onClick={() => rejectRequest(requestData)} loading={loadingState}>Reject</Button>
                        </Space>
                    </> : <>
                        <Tag>{ requestData.status }</Tag>
                    </>
                }
            </> : <>
                <p>Loading</p>
            </> }
            
        </Modal>
    
    </>

}

export default ViewRequestModel