import React from 'react';

interface BBCodeParserProps {
  text: string; 
}

const parseBBCode = (text: string): React.ReactNode => {
  const boldRegex = /\[b\](.*?)\[\/b\]/g;

  const boldMatches: RegExpExecArray[] = [];
  let match;
  while ((match = boldRegex.exec(text)) !== null) {
    boldMatches.push(match);
  }

  if (boldMatches.length === 0) {
    return text;
  }

  let parsedText: React.ReactNode[] = [];
  let lastIndex = 0;

  boldMatches.forEach((match, index) => {
    const fullMatch = match[0];
    const innerText = match[1];
    const matchIndex = match.index;

    if (matchIndex > lastIndex) {
      parsedText.push(text.slice(lastIndex, matchIndex));
    }

    const parsedInnerText = parseBBCode(innerText);

    parsedText.push(<strong key={`bold-${index}`}>{parsedInnerText}</strong>);

    lastIndex = matchIndex + fullMatch.length;
  });

  if (lastIndex < text.length) {
    parsedText.push(text.slice(lastIndex));
  }

  return parsedText;
};

const BBCodeParser: React.FC<BBCodeParserProps> = ({ text }) => {
  const lines = text.split('\n');

  const parsedText = lines.map((line, index) => {
    const parsedLine = parseBBCode(line);

    return (
      <React.Fragment key={index}>
        {parsedLine}
        {index < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });

  return <div>{parsedText}</div>;
};

export default BBCodeParser;