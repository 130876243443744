import { useEffect, useState } from "react";
import {
    Row,
    Col,
    Form,
    Typography,
    Select,
    Checkbox,
    Button,
    message,
    Input,
    Modal
 } from "antd";
import { createUserRequest, getDistributorDetail, getUserRequestToUser, userRequestType } from "../../apiBridge";
import { IDistributionDetail, IRequestParentToUser, IRequestTo } from "../../interface";
import { useLoaderData, useNavigate } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";

 const { Title, Text } = Typography;

 interface Props {
    from: string;
    isOpen: boolean;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
    reloadController: React.Dispatch<React.SetStateAction<boolean>>;
    notifController: MessageInstance;
}

const CreateRequestModal: React.FC<Props> = ({
    from,
    isOpen,
    controller,
    reloadController,
    notifController
}) => {

    const [fromData, setFromData] = useState<IRequestTo | null>(null);

    const [requestType, setRequestType] = useState<userRequestType | null>(null);
    const [reasonType, setReasonType] = useState<string>();
    const [extraReason, setExtraReason] = useState<string>('');
    const [allowReasonTypes, setAllowReasonTypes] = useState<Array<any>>([])
    const [userCheckboxOptions, setUserCheckboxOptions] = useState<Array<any>>([])
    const [targetAccountIds, setTargetAccountIds] = useState<Array<string>>([]);
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [saveState, setSaveState] = useState<boolean>(false);


    const [extraOptions, setExtraOptions] = useState<Array<any>>([])
    const [extraId, setExtraId] = useState<String>();

    useEffect(() => {
        setLoadingState(true);
        setTargetAccountIds([])
        setRequestType(null);
        setReasonType('');
        setExtraId('')

        if (isOpen) {
            let parts = from?.split(":")
            if (!parts || parts.length != 2)return

            
    
            reloadToUser(parts)
        }
        
    }, [isOpen])

    async function reloadToUser(parts: string[]) {
        let requestResult = {
            from,
            parent_id: "",
            parent_name: "",
            parent_role: "",
            users: []
      } as IRequestTo;
      
      const userRes = await getUserRequestToUser()
      if (userRes.status === 200) {
          const parentRes = await userRes.json() as IRequestParentToUser;
            
          requestResult.parent_id = parentRes.parent_id
          requestResult.parent_name = parentRes.parent_name;
          requestResult.parent_role = parentRes.parent_role;
      }

      if (parts[0] === "distributor") {
        let distribution_name :string = parts[1]
        console.log("Getting distributor " + distribution_name);
        const res = await getDistributorDetail({ distribution_name });
        if (res.status === 200) {
            const data = (await res.json()) as IDistributionDetail;

            let to_users = [];
            for (let i = 0; i < data.distribution_members.length; i++) {
            to_users.push({
                user_id: data.distribution_members[i].user_id,
                name: data.distribution_members[i].name,
                role: null,
                blocked: data.distribution_members[i].blocked
            })
            }

            requestResult.users = to_users
        }
      }

      setFromData(requestResult)
      setLoadingState(false);
    }


    const typeOptions = [
        //{label: "Account Deletion", value: "Account.Deletion"},
        {label: "Account Deactivion", value: "Account.Deactivation"},
        {label: "Account Activation", value: "Account.Activation"},
        //{label: "Account Reassign", value: "Account.Reassign"}
    ];

    const reasonMap :Record<userRequestType, string[]> = {
        "Account.Deletion": [
            "The account is no longer required",
            "The account was created mistakenly"
        ],
        "Account.Deactivation": [
            "The user(s) has left the organization",
            "The user(s)/organization has violated certain rules and regulations.",
            "The account was created with inaccurate information",
            "The account was created mistakenly"
        ],
        "Account.Activation": [
            "The user(s) has returned to the organization.",
            "The user(s) was allowed to use their account again"
        ],
        "Account.Reassign": [

        ]
    }

    const onChangeRequestType = (value: userRequestType) => {
        if (!fromData) return 

        setTargetAccountIds([])
        setRequestType(value);
        setReasonType('');
        setExtraId('')

        let reasonOptions = [];
        if (reasonMap[value]) {
            for (let i = 0; i < reasonMap[value].length; i++) {
                reasonOptions.push({
                    label: reasonMap[value][i],
                    value: reasonMap[value][i]
                })
            }

            reasonOptions.push({
                label: "Other, please state",
                value: "Other"
            })
        }

        let extraOptions = [];

        let userCheckbox = [];
        for (let i = 0; i < fromData.users.length; i++) {
            const user = fromData.users[i];
            let disabled = false;
            if (value === "Account.Deactivation" && user.blocked) {
                disabled = true;
            } else if (value === "Account.Activation" && !user.blocked) {
                disabled = true;
            }

            if (value === 'Account.Reassign' && !user.blocked) {
                extraOptions.push({
                    label: user.name,
                    value: user.user_id,
                })
            }

            userCheckbox.push({
                label: user.name,
                value: user.user_id,
                disabled: disabled
            })
        }

        setExtraOptions(extraOptions);
        setUserCheckboxOptions(userCheckbox);
        setAllowReasonTypes(reasonOptions)
    }

    async function onCreateRequest() {
        if (!fromData) {
            notifController.info("data not init")
            return false;
        }

        if (!requestType) {
            notifController.info("please select request type");
            return false;
        }

        if (targetAccountIds.length === 0) {
            notifController.info("please select target account");
            return false;
        }

        if (!reasonType ||reasonType === "") {
            notifController.info("please select reason");
            return false;
        }

        if (reasonType === "Other" && extraReason === "") {
            notifController.info("reason can not be empty");
            return false;
        }


        setSaveState(true);

        const res = await createUserRequest({
            type: requestType,
            from: fromData.from,
            requested_ids: targetAccountIds,
            reason: reasonType === 'Other' ? extraReason : reasonType,
        });

        if (res.status === 200) {
            const data = await res.json();
            notifController.success("request created!")

            controller(false)
            reloadController(true) 
        } else {
            notifController.info("network error")
        }

        setSaveState(false);
    }

    return (
        <>
         <Modal
            footer={null}
            centered
            open={isOpen}
            onCancel={() => controller(false)}
        >
            <Title level={4}>Create Request</Title>


            { loadingState ? <> <p>Loading</p> </> : <>
            
                <Row>
                <Col span={24}>
                    <Form layout="vertical" name="createRequestForm" onFinish={onCreateRequest}>
                        <Form.Item label="Request To">
                            { fromData?.parent_name }
                        </Form.Item>
                        <Form.Item label="Request Type">
                            <Select options={typeOptions} onChange={onChangeRequestType} value={requestType} />
                        </Form.Item>
                        <Form.Item label="Target Account(s)">
                            {
                                userCheckboxOptions.length > 0 ? <>
                                    <Checkbox.Group options={userCheckboxOptions} value={targetAccountIds} onChange={setTargetAccountIds} />
                                </> : (
                                    allowReasonTypes.length === 0 ? <>
                                        <Text>Please select a request type first.</Text>
                                    </> : <>
                                        <Text>No matched users.</Text>
                                    </>
                                )
                            }
                        </Form.Item>

                        {
                            requestType === "Account.Reassign" ? <>
                                <Form.Item label="Assign to">
                                    <Select options={extraOptions} onChange={setExtraId} value={extraId} />
                                </Form.Item>
                            </> : <></>
                        }

                        <Form.Item label="Request Reason">
                            {
                                allowReasonTypes.length > 0 ? <>
                                    <Select placeholder="Please select one" options={allowReasonTypes} onChange={setReasonType} value={reasonType} />
                                </> : <>
                                    <Text>Please select a request type first.</Text>
                                </>
                            }
                        </Form.Item>

                        {
                            reasonType === 'Other' ? <>
                                <Form.Item >
                                    <Input.TextArea value={extraReason} /> 
                                </Form.Item>
                            </> : <></>
                        }

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={saveState}>
                                Create Request
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            
            </> }
            
            </Modal>
        </>
    )

}

export default CreateRequestModal