import { Divider, Modal, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { IMessageDetail, IRequestListItem } from "../../interface";
import { getMessageById } from "../../apiBridge";
import { CloseOutlined } from "@ant-design/icons"
import BBCodeParser from "./bbcodeParser";
const { Title, Text, Paragraph } = Typography;

interface Props {
    id: string;
    isOpen: boolean;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageReadModal: React.FC<Props> = ({
    id,
    isOpen,
    controller
}) => {

    const [msgData, setMsgData] = useState<IMessageDetail | null>(null)

    async function reloadMessage() {
        let res = await getMessageById(String(id))
        if (res.status == 200) {
            const data = await res.json() as {
                message: IMessageDetail
            }

            console.info(data);
            setMsgData(data.message);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setMsgData(null);
            reloadMessage();
        }
    }, [isOpen])


    return (
        <Modal
            footer={null}
            centered
            open={isOpen}
            onCancel={() => controller(false)}
        >

             {
                msgData ? <>
                    <Title level={3}>{ msgData.title }</Title>
                    <Text type="secondary">From: { msgData.sender_name }   To: { msgData.receiver_name }</Text>

                    <BBCodeParser text={msgData.content} />
                    
                </> : <Spin spinning={true} />
            }
        </Modal>
    )
}

export default MessageReadModal;