import { useState } from "react";
import {
    Row,
    Col,
    Form,
    Typography,
    Select,
    Checkbox,
    Button,
    message,
    Input
 } from "antd";
import { createUserRequest, userRequestType } from "../../apiBridge";
import { IRequestTo } from "../../interface";
import { useLoaderData, useNavigate } from "react-router-dom";

 const { Title, Text } = Typography;


const CreateUserRequest: React.FC = () => {
    const data = useLoaderData() as IRequestTo;

    const [messageApi, contextHolder] = message.useMessage();
    const [requestType, setRequestType] = useState<userRequestType | null>(null);
    const [reasonType, setReasonType] = useState<string>();
    const [extraReason, setExtraReason] = useState<string>('');
    const [allowReasonTypes, setAllowReasonTypes] = useState<Array<any>>([])
    const [userCheckboxOptions, setUserCheckboxOptions] = useState<Array<any>>([])
    const [targetAccountIds, setTargetAccountIds] = useState<Array<string>>([]);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [extraOptions, setExtraOptions] = useState<Array<any>>([])
    const [extraId, setExtraId] = useState<String>();

    const navigate = useNavigate();

    const typeOptions = [
        {label: "Account Deletion", value: "Account.Deletion"},
        {label: "Account Deactivion", value: "Account.Deactivation"},
        {label: "Account Activation", value: "Account.Activation"},
        {label: "Account Reassign", value: "Account.Reassign"}
    ];

    const reasonMap :Record<userRequestType, string[]> = {
        "Account.Deletion": [
            "The account is no longer required",
            "The account was created mistakenly"
        ],
        "Account.Deactivation": [
            "The user(s) has left the organization",
            "The user(s)/organization has violated certain rules and regulations.",
            "The account was created with inaccurate information",
            "The account was created mistakenly"
        ],
        "Account.Activation": [
            "The user(s) has returned to the organization.",
            "The user(s) was allowed to use their account again"
        ],
        "Account.Reassign": [

        ]
    }

    const onChangeRequestType = (value: userRequestType) => {
        setTargetAccountIds([])
        setRequestType(value);
        setReasonType('');
        setExtraId('')

        let reasonOptions = [];
        if (reasonMap[value]) {
            for (let i = 0; i < reasonMap[value].length; i++) {
                reasonOptions.push({
                    label: reasonMap[value][i],
                    value: reasonMap[value][i]
                })
            }

            reasonOptions.push({
                label: "Other, please state",
                value: "Other"
            })
        }

        let extraOptions = [];

        let userCheckbox = [];
        for (let i = 0; i < data.users.length; i++) {
            const user = data.users[i];
            let disabled = false;
            if (value === "Account.Deactivation" && user.blocked) {
                disabled = true;
            } else if (value === "Account.Activation" && !user.blocked) {
                disabled = true;
            }

            if (value === 'Account.Reassign' && !user.blocked) {
                extraOptions.push({
                    label: user.name,
                    value: user.user_id,
                })
            }

            userCheckbox.push({
                label: user.name,
                value: user.user_id,
                disabled: disabled
            })
        }

        setExtraOptions(extraOptions);
        setUserCheckboxOptions(userCheckbox);
        setAllowReasonTypes(reasonOptions)
    }

    async function onCreateRequest() {
        if (!requestType) {
            messageApi.info("please select request type");
            return false;
        }

        if (targetAccountIds.length === 0) {
            messageApi.info("please select target account");
            return false;
        }

        if (!reasonType ||reasonType === "") {
            messageApi.info("please select reason");
            return false;
        }

        if (reasonType === "Other" && extraReason === "") {
            messageApi.info("reason can not be empty");
            return false;
        }


        setLoadingState(true);

        const res = await createUserRequest({
            type: requestType,
            from: data.from,
            requested_ids: targetAccountIds,
            reason: reasonType === 'Other' ? extraReason : reasonType,
        });

        if (res.status === 200) {
            const data = await res.json();
            messageApi.success("request created!")

            navigate("/request")            
        } else {
            messageApi.info("network error")
        }

        setLoadingState(false);
    }

    return (
        <>
            {contextHolder}
            <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row>
                <Col span={24}>
                    <Title level={4}>Create Request</Title>
                    <Form layout="vertical" name="createRequestForm" onFinish={onCreateRequest}>
                        <Form.Item label="Request To">
                            { data.parent_name }
                        </Form.Item>
                        <Form.Item label="Request Type">
                            <Select options={typeOptions} onChange={onChangeRequestType} value={requestType} />
                        </Form.Item>
                        <Form.Item label="Target Account(s)">
                            {
                                userCheckboxOptions.length > 0 ? <>
                                    <Checkbox.Group options={userCheckboxOptions} value={targetAccountIds} onChange={setTargetAccountIds} />
                                </> : (
                                    allowReasonTypes.length === 0 ? <>
                                        <Text>Please select a request type first.</Text>
                                    </> : <>
                                        <Text>No matched users.</Text>
                                    </>
                                )
                            }
                        </Form.Item>

                        {
                            requestType === "Account.Reassign" ? <>
                                <Form.Item label="Assign to">
                                    <Select options={extraOptions} onChange={setExtraId} value={extraId} />
                                </Form.Item>
                            </> : <></>
                        }

                        <Form.Item label="Request Reason">
                            {
                                allowReasonTypes.length > 0 ? <>
                                    <Select placeholder="Please select one" options={allowReasonTypes} onChange={setReasonType} value={reasonType} />
                                </> : <>
                                    <Text>Please select a request type first.</Text>
                                </>
                            }
                        </Form.Item>

                        {
                            reasonType === 'Other' ? <>
                                <Form.Item >
                                    <Input.TextArea value={extraReason} /> 
                                </Form.Item>
                            </> : <></>
                        }

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loadingState}>
                                Create Request
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            </div>
        </>
    )

}

export default CreateUserRequest