import { Button, Checkbox, Divider, Form, Input, Modal, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { IMessageDetail } from "../../interface";
import { createMessage, deleteMessageById, getMessageById, updateMessage } from "../../apiBridge";
import { CloseOutlined } from "@ant-design/icons"
import { MessageInstance } from "antd/es/message/interface";

const { Title, Text, Paragraph } = Typography;

interface Props {
    id: string;
    isOpen: boolean;
    controller: React.Dispatch<React.SetStateAction<boolean>>;
    reloadController: React.Dispatch<React.SetStateAction<boolean>>;
    notifController: MessageInstance;
}

const MessageEditModal: React.FC<Props> = ({
    id,
    isOpen,
    controller,
    reloadController,
    notifController,
}) => {

    const [msgData, setMsgData] = useState<IMessageDetail | null>(null)
    const [form] = Form.useForm();
    

    async function reloadMessage() {
        let res = await getMessageById(String(id))
        if (res.status == 200) {
            const data = await res.json() as {
                message: IMessageDetail
            }

            setMsgData(data.message);
            form.setFieldsValue({
                sender_name: data.message.sender_name,
                receiver_id: data.message.receiver_address,
                title: data.message.title,
                content: data.message.content
            })
        }
    }

    const deleteMessage = async() => {
        if (!id) return false;

        // eslint-disable-next-line no-restricted-globals
        if (!confirm('delete this message?')) {
            return false;
        }

        let res = await deleteMessageById(id);
        if (res.status === 200) {
            notifController.success("delete success");
            
            controller(false);
            reloadController(true);
        } else {
            const data = await res.json();
            notifController.error(data.error || "Unknown Error. Please try again next time.");
        }
    }

    const onSaveChanges = async (values: any) => {
        console.info(values);
        let res;
        if (id) {
            res = await updateMessage(id, values);
        } else {
            res = await createMessage(values);
        }
        
        if (res.status === 200) {
            notifController.success("save complete");

            
            controller(false);
            reloadController(true);
        } else {
            const data = await res.json();
            notifController.error(data.error || "Unknown Error. Please try again next time.");
        }
    };

    useEffect(() => {
        setMsgData(null);

        if (id !== "") {
            reloadMessage();
        } else {
            form.resetFields()
        }
    }, [id])


    return (
        <Modal
            footer={null}
            centered
            open={isOpen}
            onCancel={() => controller(false)}
        >

        {
                msgData || id === "" ? <>
                    <Title level={3}>{msgData ? "Edit" : "Create"} Message</Title>
                    <Form
                        name="messageEditForm"
                        layout="vertical"
                        autoComplete="off"
                        form={form}
                        onFinish={onSaveChanges}
                        validateTrigger="onSubmit"
                        requiredMark={false}
                        wrapperCol={{ flex: "auto" }}
                    >

                        <Form.Item label="Send to" name="receiver_id">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Sender Name" name="sender_name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Title" name="title">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Content" name="content">
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item name="send_now"  valuePropName="checked">
                            <Checkbox>Send now</Checkbox>
                        </Form.Item>

                        <div>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button onClick={() => controller(false)}>Cancel</Button>
                            </Space>

                            {
                                id ? <>
                                <div style={{ float: "right" }}>
                                    <Button danger onClick={() => deleteMessage()}>Delete</Button>
                                </div>
                                </> : <></>
                            }
                        </div>
                    </Form>
                </> : <Spin spinning={true} />
        }

           
        </Modal>
    )
}

export default MessageEditModal;