import { MouseEventHandler, useEffect, useState } from "react";
import {
    Row,
    Col,
    Typography,
    Form,
    DatePicker,
    Space,
    Button,
    Table,
    message,
    Pagination,
    type TableColumnsType,
    Select,
 } from "antd";
import type { TimeRangePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { IAuditLogItem } from "../../interface";
import { queryAuditLog } from "../../apiBridge";

const { Title } = Typography;
const { RangePicker } = DatePicker;


const AuditLogs: React.FC = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const [items, setItems] = useState<IAuditLogItem[]>([]);
    const [loadingState, setLoadingState] = useState(false);
    const [startTime, setStartTime] = useState<Dayjs | null>(null)
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const [searchType, setSearchType] = useState<string>('');
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);

    const columns: TableColumnsType<IAuditLogItem> = [
        {
            title: "Type",
            key: "type",
            dataIndex: "type"
        },
        {
            title: "Action",
            key: "message",
            dataIndex: "message",
        },
        {
            title: "Request By",
            render: (text, row) => {
                return (<>{row.operator_name}</>)
            },
        },
        {
            title: "Affected User",
            render: (text, row) => {
                return (<>{row.to_user_name}</>)
            },
        },
        {
            title: "Time",
            dataIndex: "ts",
            render: (text, row) => {
                let ts = dayjs(row.ts).format("YYYY-MM-DD HH:mm")
                return (<>{ts}</>)
            },
        }
    ];
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    ];

    const typeOptions = [
        {label: "All (except login)", value: ""},
        {label: "Account Login", value: "Login"},
        {label: "Account Creation", value: "Account.Creation"},
        {label: "Account Deletion", value: "Account.Deletion"},
        {label: "Account Deactivation", value: "Account.Deactivation"},
        {label: "Account Reassigning", value: "Account.Reassign"},
        {label: "User Sending a Request", value: "Request.Create"},
        {label: "Editing and Saving an Account Page", value: "Profile.Update"},
    ];


    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
          setStartTime(dates[0]);
          setEndTime(dates[1]);
        } else {
          console.log('Clear');
        }
    };

    function updatePage(page: number, size: number) {
        setPageSize(size);

        doQueryAuditLog((page - 1) * size);
    }

    const doNewQuery = (event: any) => {
        doQueryAuditLog(0)
    }

    const doQueryAuditLog = async(offset: number) :Promise<any> => {
        if (!startTime || !endTime) {
            messageApi.info("please select time");
            return ;
        }

        setLoadingState(true)
        let res = await queryAuditLog({
            start_at: startTime,
            end_at: endTime,
            limit: pageSize,
            offset,
            type: searchType
        });

        console.info(res.body);
        
        if (res.status === 200) {
            const data = await res.json();
            
            setItems(data.items)
            setTotalCount(data.count)
          }

        setLoadingState(false)
    };
      

    return (<>
         {contextHolder}
        <Row>
            <Col span={24}>
                <div
                    style={{
                    background: "white",
                    borderRadius: 4,
                    boxShadow: "0 0 8px 0 #cbcbcb",
                    padding: 20,
                    }}
                >

                    <Row gutter={[24, 24]}>
                        <Col>
                            <Title level={4} style={{ marginBottom: 0 }}>Audit Log</Title>

                            <Form>
                                <Form.Item label="Date">
                                    <RangePicker presets={rangePresets} onChange={onRangeChange} />
                                </Form.Item>

                                <Form.Item label="Type">
                                    <Select options={typeOptions} onChange={setSearchType} defaultValue={searchType} />
                                </Form.Item>

                                <div >
                                    <Space>
                                        <Button onClick={doNewQuery}>Query</Button>
                                    </Space>
                                </div>
                            </Form>
                        </Col>

                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={items}
                                loading={loadingState}
                                pagination={false}
                                rowKey="id"
                                scroll={{
                                    x: "max-content",
                                }}
                            />
                        </Col>

                        <Col>
                            <Pagination total={totalCount} pageSize={pageSize} showSizeChanger={false} onChange={updatePage} />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    </>)
}

export default AuditLogs