export const regionList = [
    "Azerbaijan",
    "China",
    "Hong Kong",
    "Hungary",
    "India",
    "Individual Neutral Athletes",
    "Japan",
    "Jamaica",
    "Korea",
    "Lesotho",
    "Malaysia",
    "Mali",
    "New Zealand",
    "Pakistan",
    "Philippines",
    "Rwanda",
    "Saudi Arabia",
    "Singapore",
    "Sri Lanka",
    "Taiwan",
    "Thailand",
    "United Arab Emirates",
    "United States of America",
    "Vietnam",
    "World Taekwondo Refugee Olympic Team"
];