import { MouseEventHandler, useEffect, useState } from "react";
import {
    Row,
    Col,
    Typography,
    Form,
    DatePicker,
    Space,
    Button,
    Table,
    message,
    Pagination,
    type TableColumnsType,
    Select,
    Tabs,
    List,
    Menu,
    Avatar,
    Skeleton,
    Divider,
    Tag,
 } from "antd";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { MenuProps } from "rc-menu";
import { IMessageListItem } from "../../interface";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ViewMessageModel from "./viewMessageModal";
import EditMessageModal from './editMessageModal';
import { getMessageInbox, getMessageOutbox } from "../../apiBridge";

dayjs.extend(relativeTime);

const { Title, Text } = Typography;

const MessageList: React.FC = () => {
    
    const params = useParams()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    const data = useLoaderData() as {
        messages: IMessageListItem[],
        count: number,
        unread? :number
    }

    const messagePerSize = 20;

    const [triggerTableReload, setTriggerTableReload] = useState(false);
    const [listItems, setListItems] = useState<IMessageListItem[]>(data.messages)
    const [navTab, setNavTab] = useState<string>(params.type as string);
    const [hasMore, setHasMore] = useState<boolean>(data.messages.length >= messagePerSize)
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [isOpenRead, setIsOpenRead] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [targetMessageId, setTargetMessageId] = useState<string>("");
    const [navOffset, setNavOffset] = useState<number>(0);

    useEffect(() => {
        if (triggerTableReload) {
            setTriggerTableReload(false);
            reloadItems()
        }
      }, [triggerTableReload]);
    

    useEffect(() => {
        console.info("Effect", data);

        setListItems(data.messages);
        setHasMore(data.messages.length >= messagePerSize)
        setLoadingState(false);
    }, [data])

    const loadMoreData = async () => {
        if (!hasMore) return false;

        setLoadingState(true)

        let offset = navOffset + messagePerSize;
        setNavOffset(offset);

        const res = await getListItems(navTab, offset, messagePerSize);
        if (res) {        
            setListItems([...listItems, ...res.messages]);
            setHasMore(res.messages.length >= messagePerSize)
        }

        setLoadingState(false);
    };

    const reloadItems = async() => {
        setLoadingState(true)

        const res = await getListItems(navTab, 0, messagePerSize)

        if (res) {
            setListItems(res.messages);
            setHasMore(res.messages.length >= messagePerSize)
        }
        setLoadingState(false);
    }

    const getListItems = async (type: string, offset: number, limit: number) => {
        if (type === "inbox") {
            const res = await getMessageInbox(false, offset, limit);

            if (res.status === 200) {
                const data = await res.json() as {
                    count: number,
                    messages: IMessageListItem[]
                }

                return {
                    messages: data.messages,
                    count: data.count
                }
            }

            return null
        } 
        
        const res = await getMessageOutbox(type === 'draft' ? true : false, offset, limit);
        if (res.status === 200) {
            const data = await res.json() as {
                count: number,
                messages: IMessageListItem[]
            }
        
            return data;
        }
        
        return null
    }

    const newMessage = () => {
        setTargetMessageId("")
        setIsOpenEdit(true)
    }

    function openMessage(e: IMessageListItem) {
        console.info(e);
        setTargetMessageId(e.id);

        if (navTab === "inbox" || navTab === "outbox") {
            setIsOpenRead(true)
        } else {
            setIsOpenEdit(true);
        }
    }

    function getMessageTs(type: string, item: any) :Dayjs  {
        //console.info("getTs", item);
        if (type === "inbox") {
            return item.send_at ? dayjs(item.send_at) : dayjs();
        } 

        return item.created_at ? dayjs(item.created_at) : dayjs();
    }

    const messageNav :MenuProps['items'] = [
        {
            key: "inbox",
            label: "Inbox"
        },
        {
            key: "outbox",
            label: "Outbox"
        },
        {
            key: "draft",
            label: "Draft"
        },
        {
            key: "request",
            label: "Request"
        }
    ];

    const onNavClick: MenuProps['onClick'] = (e) => {
        setNavTab(e.key);
        setListItems([]);

        setLoadingState(true)
        if (e.key === 'request') {
            navigate("/message/request/");
        } else {
            navigate("/message/" + e.key);
        }    
    };

    return <>
        {contextHolder}
        <ViewMessageModel isOpen={isOpenRead} controller={setIsOpenRead} id={targetMessageId} />
        <EditMessageModal isOpen={isOpenEdit} controller={setIsOpenEdit} id={targetMessageId} notifController={messageApi} reloadController={setTriggerTableReload} />

        <Row>
            <Col span={24}>
                <div
                    style={{
                    background: "white",
                    borderRadius: 4,
                    boxShadow: "0 0 8px 0 #cbcbcb",
                    padding: 20,
                    }}
                >
                        <Title level={4}>Messages</Title>
                        
                        <Row gutter={[24, 24]}>
                            <Col sm={4} xs={24}>
                                <Menu style={{width: "100%"}} onClick={onNavClick} mode="vertical" items={messageNav} selectedKeys={[navTab]} />
                            </Col>

                            <Col sm={20} xs={24}>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 350,
                                    overflow: 'auto',
                                    padding: '0 16px',
                                }}>
                                    <InfiniteScroll
                                        dataLength={listItems.length}
                                        next={loadMoreData}
                                        hasMore={hasMore}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                        endMessage={<Divider plain>It is all, nothing more</Divider>}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <List 
                                            loading={loadingState}
                                            dataSource={listItems} 
                                            renderItem={(item) => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta 
                                                        avatar={<Avatar src={item.avatar} />}
                                                        title={<a onClick={() => openMessage(item)}>{item.title}</a>}
                                                        description={(navTab == "inbox" && !item.read_at ? <><Tag>Unread</Tag> { item.summary }</> : <>{ item.summary }</>)}
                                                    />
                                                    <div style={{textAlign: "right"}}>
                                                        { getMessageTs(navTab, item).format("Do MMM, YYYY") }<br />
                                                        <Text type="secondary">{ getMessageTs(navTab, item).from(Date.now()) }</Text>
                                                    </div>
                                                </List.Item>
                                              )}
                                            />    
                                    </InfiniteScroll>
                                </div>

                                <Button onClick={() => newMessage()}>Create Message</Button>
                            </Col>
                        </Row>

                </div>
            </Col>
        </Row>
    </>
}

export default MessageList