import {
    useLoaderData,
    useOutletContext,
} from "react-router-dom";
import { IOrganizationPlayerListItem } from "../../interface";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CreatePlayerUserModal from "./createPlayerModal";
import DeletePlayerUserModal from "./deletePlayerModal";
import EditPlayerUserModal from './editPlayerModal';

import {
    Col,
    Row,
    Table,
    type TableColumnsType,
    Typography,
    Input,
    Space,
    Tag,
    Grid,
    Button,
    message,
    Modal,
    Checkbox,
} from "antd";
import Highlighter from "react-highlight-words";
import { useEffect, useState } from "react";
import Link from "antd/es/typography/Link";
import Search from "antd/es/input/Search";
import { cancelDeleteOrganizationPlayer, getOrganizationPlayers } from "../../apiBridge";
  
const { Title } = Typography;
const { useBreakpoint } = Grid;

const SignleOrganization : React.FC = () => {
    const screens = useBreakpoint();
    const data = useLoaderData() as  {
      users: IOrganizationPlayerListItem[];
      organization: { id: string; name: string };
    };

    const [loadingState, setLoadingState] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [onRecyleBin, setOnRecybin] = useState(false);

    const [usersData, setUserData] = useState<IOrganizationPlayerListItem[]>(data.users);
    const [filteredData, setFilteredData] = useState<IOrganizationPlayerListItem[]>(data.users);

    const [triggerTableReload, setTriggerTableReload] = useState(false)
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

    const [targetUser, setTargetUser] = useState<IOrganizationPlayerListItem|null>(null)


    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (usersData.length > 0) {
          if (searchValue) {
            const temp = usersData.filter(
              (user) =>
                (user.disabled === onRecyleBin) && (user.username.includes(searchValue) ||
                user.alias.includes(searchValue))
            );
            setFilteredData(temp);
          } else {
            const temp = usersData.filter(
                (user) =>
                    user.disabled === onRecyleBin
              );

            setFilteredData(temp);
          }
        }
    }, [usersData, searchValue]);

    useEffect(() => {
        const getUsersFromOrganisation = async () => {
            const res = await getOrganizationPlayers(data.organization.id)
            setLoadingState(false);
            setTriggerTableReload(false);
            if (res.status === 200) {
              const temp = await res.json();
              
              setUserData(temp.users);
            }
          };
        
          if (triggerTableReload) {
            setLoadingState(true)
            getUsersFromOrganisation()
          }
    }, [triggerTableReload])

    const onUserSearch = (value: string) => {
        setSearchValue(value);
      };

    const editUser = (record: IOrganizationPlayerListItem) => {
        setTargetUser(record);
        setIsEditUserModalOpen(true)
    };

    const deleteUser = (record: IOrganizationPlayerListItem) => {
        setTargetUser(record)
        setIsDeleteUserModalOpen(true);
    };

    const createUser = () => {
        setIsCreateUserModalOpen(true)
    }

    const updateRecyleBin = () => {
        setLoadingState(true)
        setOnRecybin(!onRecyleBin);
        setTriggerTableReload(true)
    }

    const restoreUser = async(record: IOrganizationPlayerListItem) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("restore this account?")) {
            return false;
        }

        let res = await cancelDeleteOrganizationPlayer(data.organization.id, record.id)
        if (res && res.status === 200) {
            messageApi.success("restore success");
            setTriggerTableReload(true);
        } 
    }
    

    const columns: TableColumnsType<IOrganizationPlayerListItem> = [
        {
            title: "Ref ID",
            key: "ref_id",
            dataIndex: "ref_id",
            sorter: (a, b) => a.ref_id.localeCompare(b.ref_id),
            render: (text, row) => (
                <div>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                  />
                </div>
            ),
        },
        {
            title: "Username",
            key: "username",
            dataIndex: "username",
            render: (text, row) => (
                <div>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                  />
                </div>
            ),
        },
        {
            title: "Display Name",
            key: "alias",
            dataIndex: "alias",
            sorter: (a, b) => a.alias.localeCompare(b.alias),
            render: (text, row) => (
                <div>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                  />
                </div>
            ),
        },
        {
            title: "Role",
            key: "role",
            dataIndex: "role",
            sorter: (a, b) => a.role.localeCompare(b.role),
            render: (value) => (
              <Tag color={value === "GameMaster" ? "purple" : undefined}>
                {value === "GameMaster" ? "Game Master" : "Player"}
              </Tag>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (record.disabled ? <Space size="middle">
                    <Link onClick={() => restoreUser(record)}>Restore</Link>
                </Space> : <Space size="middle">
                    <Link onClick={() => editUser(record)}><EditOutlined /></Link>
                    <Link type="danger" onClick={() => deleteUser(record)}>
                        <DeleteOutlined />
                    </Link>
                </Space>)
            }
        }
    ];
    console.info("single org", data);


    return (
        <>
        {contextHolder}
        <EditPlayerUserModal
            organizationId={data.organization.id}
            isOpen={isEditUserModalOpen}
            user={targetUser}
            messageController={messageApi}
            controller={setIsEditUserModalOpen}
            reloadController={setTriggerTableReload}
        />

        <CreatePlayerUserModal 
            organizationId={data.organization.id}
            isOpen={isCreateUserModalOpen} 
            messageController={messageApi} 
            controller={setIsCreateUserModalOpen} 
            reloadController={setTriggerTableReload} />

        <DeletePlayerUserModal 
            organizationId={data.organization.id}
            isOpen={isDeleteUserModalOpen}
            user={targetUser}
            messageController={messageApi}
            controller={setIsDeleteUserModalOpen}
            reloadController={setTriggerTableReload}
            />


          <Row>
            <Col span={24}>
                <div
                    style={{
                    background: "white",
                    borderRadius: 4,
                    boxShadow: "0 0 8px 0 #cbcbcb",
                    padding: 20,
                    }}
                >
                    <Row gutter={[24, 24]}>
                        <Col flex="auto">
                            <Title level={4}>{ data.organization.name }</Title>
                        </Col>
                        {!screens.xs && (
                            <Col
                                flex="auto"
                                span={screens.xs ? undefined : 24}
                                style={{ display: "flex", justifyContent: "end" }}
                            >
                                <Space size="middle">
                                    <Checkbox onClick={() => updateRecyleBin()} checked={onRecyleBin}>Recycle bin</Checkbox>

                                {!screens.xs && (
                                    <Search
                                    placeholder="Search User"
                                    allowClear
                                    disabled={loadingState}
                                    onChange={(e) => onUserSearch(e.target.value)}
                                    onSearch={onUserSearch}
                                    value={searchValue}
                                    />
                                )}
                                    <Button 
                                        type="primary"
                                        disabled={loadingState}
                                        onClick={() => createUser()}
                                    >
                                    <PlusOutlined /> Create User
                                    </Button>
                                </Space>
                            </Col>
                        )}

                        {screens.xs && (
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    <Col flex="auto">
                                        <Search
                                        placeholder="Search User"
                                        allowClear
                                        disabled={loadingState}
                                        onChange={(e) => onUserSearch(e.target.value)}
                                        onSearch={onUserSearch}
                                        />
                                    </Col>
                                    <Col flex="32px">
                                        <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        disabled={loadingState}
                                        onClick={() => setIsCreateUserModalOpen(true)}
                                        >
                                        {!screens.xs ? "Create User" : ""}
                                        </Button>
                                    </Col>
                                </Row>

                                <Row gutter={[20, 20]}>
                                    <Col>
                                        <Checkbox onClick={() => updateRecyleBin()} checked={onRecyleBin}>Recycle bin</Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        )}

                        <Col span={24}>
                            <Table
                                loading={loadingState} 
                                columns={columns} 
                                dataSource={filteredData} 
                                rowKey="id" 
                                scroll={{
                                    x: "max-content",
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                </Col>
            </Row>
        </>
    )
}

export default SignleOrganization