import { useEffect, useState } from "react";
import {
  useLoaderData,
  useRevalidator,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import {
  IAuthUser,
  IOrganization
} from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Button,
  Space,
  FloatButton,
  Grid,
  message,
  Tag,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { createFirstInvoice, disableSubscription } from "../../apiBridge";
import OrganizationModal from "./organizationModal";
import ManualModal from "./manualModal";

const { Title } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;

const Organizations: React.FC = () => {
  const data = useLoaderData() as IOrganization[];
  const screens = useBreakpoint();
  const revalidate = useRevalidator();
  const { authUser } = useOutletContext() as { authUser: IAuthUser };
  const navigate = useNavigate()

  console.log(data);

  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IOrganization[]>(data);
  const [createOrgModalOpen, setCreateOrgModalOpen] = useState(false);
  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [manualOrgId, setManualOrgId] = useState("");

  useEffect(() => {
    if (data?.length > 0) {
      if (searchValue) {
        const temp = data.filter(
          (organization) =>
            organization.organization_name.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        setFilteredData(data);
      }
    }
  }, [data, searchValue]);

  const handleStripeButton = async (organization_id: string) => {
    // confirm stripe payment
    const confirmation = window.confirm("Are you sure you want to create a stripe invoice?");

    if (confirmation) {
      const res = await createFirstInvoice({ organization_id });
      if (res.status === 200) {
        revalidate.revalidate();
      }
      messageApi.success("Stripe invoice created successfully.");
    } else {
      messageApi.error("Stripe invoice creation cancelled.");
    }
  }

  const handleDisableButton = async (subscription_id: string) => {
    // confirm stripe payment
    const confirmation = window.confirm("Are you sure you want to cancel the subscription?");

    if (confirmation) {
      const res = await disableSubscription({ subscription_id });
      if (res.status === 200) {
        revalidate.revalidate();
      }
      messageApi.success("Subscription cancelled successfully.");
    } else {
      messageApi.error("Subscription cancellation cancelled.");
    }
  }

  const columns: TableColumnsType<IOrganization> = [
    {
      title: "Name",
      key: "organization_name",
      dataIndex: "organization_name",
      sorter: (a, b) => a.organization_name.localeCompare(b.organization_name),
      render: (text, row) => (
        <div>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
          {text === authUser.account_info.name && (
            <Tag color="blue" style={{ marginLeft: 10 }}>
              Me
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Plan Name",
      key: "plan_name",
      dataIndex: "plan_name",
      sorter: (a, b) => a.plan_name.localeCompare(b.plan_name),
      render: (text) => (
        <div>
          {text ? (
            text
          ) : (
            <Tag color="red">No active plan</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Created On",
      key: "organization_created_at",
      dataIndex: "organization_created_at",
      sorter: (a, b) => a.organization_created_at.localeCompare(b.organization_created_at),
      render: (text) => (
        <div>
          {text !== "0001-01-01T00:00:00Z" ? (
            <Tag>{new Date(text).toLocaleDateString()}</Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Expired At",
      key: "expire_at",
      dataIndex: "expire_at",
      sorter: (a, b) => a.expire_at.localeCompare(b.expire_at),
      render: (text) => (
        <div>
          {text !== "0001-01-01T00:00:00Z" ? (
            new Date(text) > new Date() ? (
              <Tag color="green">{new Date(text).toLocaleDateString()}</Tag>
            ) : (
              <Tag color="red">{new Date(text).toLocaleDateString()}</Tag>
            )
          ) : null}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "organization_id",
      width: 200,
      render: (text, record) => (
        <Space>
          {
            new Date(record.expire_at) < new Date() ? (
              <>
                <Button onClick={() => handleStripeButton(record.organization_id)}>
                  Stripe
                </Button>
              </>
            ) : (
              <Button danger type="primary" onClick={() => handleDisableButton(record.subscription_id)}>
                Deactivate
              </Button>
            )
          }
          <Button type="primary" onClick={() => {
            setManualOrgId(record.organization_id)
            setManualModalOpen(true)
          }
          }>
            Manual
          </Button>
        
          <Button onClick={() => {
            navigate("/organization/" + record.organization_id);
          }}>Users</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <OrganizationModal
        isAdmin={authUser.account_role === "Administrator"}
        controller={setCreateOrgModalOpen}
        isOpen={createOrgModalOpen}
        notifController={messageApi}
      />
      <ManualModal
        isAdmin={authUser.account_role === "Administrator"}
        controller={setManualModalOpen}
        isOpen={manualModalOpen}
        notifController={messageApi}
        organizationId={manualOrgId} />
      <Row>
        {!screens.md && (
          <FloatButton
            icon={<PlusOutlined />}
            onClick={() => setCreateOrgModalOpen(true)}
          />
        )}
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.length} Organizations
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search Organizations"
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {screens.md && (
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => setCreateOrgModalOpen(true)}
                    >
                      Create Organization
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowKey="user_id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Organizations;
