import { MouseEventHandler, useEffect, useState } from "react";
import {
    Row,
    Col,
    Typography,
    Form,
    DatePicker,
    Space,
    Button,
    Table,
    message,
    Pagination,
    type TableColumnsType,
    Select,
    Tabs,
    List,
    Menu,
    Avatar,
    Skeleton,
    Divider,
    Tag,
    TabsProps,
 } from "antd";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { MenuProps } from "rc-menu";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IRequestList, IRequestListItem } from "../../interface";
import { queryUserRequestList, updateRequestStatus } from "../../apiBridge";
import ViewRequestModel from "./viewRequestModal";

dayjs.extend(relativeTime);

const { Title, Text } = Typography;

const UserRequests: React.FC = () => {

    const requestTabItems :TabsProps["items"] = [
        {
            key: "normal",
            label: "Requested"
        },
        {
            key: "review",
            label: "Review"
        }
    ]

    const messageNav :MenuProps['items'] = [
        {
            key: "inbox",
            label: "Inbox"
        },
        {
            key: "outbox",
            label: "Outbox"
        },
        {
            key: "draft",
            label: "Draft"
        },
        {
            key: "request",
            label: "Request"
        }
    ];

    const perSize = 20;
    const data = useLoaderData() as IRequestList;

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [hasMore, setHasMore] = useState<boolean>(false);
    const [navOffset, setNavOffset] = useState<number>(0);
    const [navTab, setNavTab] = useState<string>("request");
    const [listItems, setListItems] = useState<IRequestListItem[]>(data.items ? data.items : []);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [requestTab, setRequestTab] = useState<string>('normal')
    const [triggerTableReload, setTriggerTableReload] = useState(false);

    const [isReadOpen, setIsReadOpen] = useState<boolean>(false)
    const [viewId, setViewId] = useState<string>("")
    
    useEffect(() => {
        console.info("Effect", data);

        setListItems(data.items);
        setHasMore(data.items ? data.items.length >= perSize : false);

        setLoadingState(false);
    }, [data])

    useEffect(() => {
        if (triggerTableReload) {
            setTriggerTableReload(false);
            
            reloadItems(requestTab, 0);
        }
    }, [triggerTableReload]);

    async function reloadItems(tab: string, offset: number) {
        setLoadingState(true)
        const res = await queryUserRequestList(offset, perSize, tab === 'review');
        if (res) {        
            const data = await res.json() as IRequestList

            setListItems(data.items);
            setHasMore(data.items ? data.items.length >= perSize : false)
        }
        setLoadingState(false)
    }
    
    async function loadMoreData() {
        if (!hasMore) return false;

        let offset = navOffset + perSize;
        setNavOffset(offset);

        setLoadingState(true)
        const res = await queryUserRequestList(offset, perSize, requestTab === 'review');
        if (res) {        
            const data = await res.json() as IRequestList

            setListItems([...listItems, ...data.items]);
            setHasMore(data.items ? data.items.length >= perSize : false)
        }
        setLoadingState(false)
    } 

    function openMessage(data: IRequestListItem) {
        setViewId(data.id);
        setIsReadOpen(true);
    }

    const onRequestTabChange :TabsProps['onChange'] = (key) => {
        setRequestTab(key);
        setListItems([]);

        setLoadingState(true);

        if (key === 'normal') {
            navigate("/message/request")
        } else {
            navigate("/message/request/review")
        }
    }

    const onNavClick: MenuProps['onClick'] = (e) => {
        setNavTab(e.key);
        setListItems([]);

        setLoadingState(true)
        if (e.key === 'request') {
            navigate("/message/request/");
        } else {
            navigate("/message/" + e.key);
        }    
    };

    return <>
        {contextHolder}
        <ViewRequestModel id={viewId} isReviewer={requestTab === 'review'} controller={setIsReadOpen} reloadController={setTriggerTableReload} notifController={messageApi} isOpen={isReadOpen} />
        <Row>
            <Col span={24}>
                <div
                    style={{
                    background: "white",
                    borderRadius: 4,
                    boxShadow: "0 0 8px 0 #cbcbcb",
                    padding: 20,
                    }}
                >
                        <Title level={4}>Messages</Title>
                        
                        <Row gutter={[24, 24]}>
                            <Col sm={4} xs={24}>
                                <Menu style={{width: "100%"}} onClick={onNavClick} mode="vertical" items={messageNav} selectedKeys={[navTab]} />
                            </Col>

                            <Col sm={20} xs={24}>
                                <Tabs items={requestTabItems} activeKey={requestTab} onChange={onRequestTabChange} />

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 350,
                                    overflow: 'auto',
                                    padding: '0 16px',
                                }}>
                                    <InfiniteScroll
                                        dataLength={listItems.length}
                                        next={loadMoreData}
                                        hasMore={hasMore}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                        endMessage={<Divider plain>It is all, nothing more</Divider>}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <List 
                                            loading={loadingState}
                                            dataSource={listItems} 
                                            renderItem={(item) => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta 
                                                        avatar={<Avatar src={item.review_user.avatar} />}
                                                        title={<a onClick={() => openMessage(item)}>{item.review_user.name}</a>}
                                                        description={<><Tag>{item.status}</Tag> {item.title}</>}
                                                    />
                                                    <div style={{textAlign: "right"}}>
                                                        { dayjs(item.ts).format("Do MMM, YYYY") }<br />
                                                        <Text type="secondary">{ dayjs(item.ts).from(Date.now()) }</Text>
                                                    </div>
                                                </List.Item>
                                            )}
                                            />    
                                    </InfiniteScroll>
                                </div>

                            </Col>
                        </Row>

                </div>
            </Col>
        </Row>


    </>
};

export default UserRequests